import createLucideIcon from '../createLucideIcon';

/**
 * @component @name CalendarSync
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTB2NGg0IiAvPgogIDxwYXRoIGQ9Im0xMSAxNCAxLjUzNS0xLjYwNWE1IDUgMCAwIDEgOCAxLjUiIC8+CiAgPHBhdGggZD0iTTE2IDJ2NCIgLz4KICA8cGF0aCBkPSJtMjEgMTgtMS41MzUgMS42MDVhNSA1IDAgMCAxLTgtMS41IiAvPgogIDxwYXRoIGQ9Ik0yMSAyMnYtNGgtNCIgLz4KICA8cGF0aCBkPSJNMjEgOC41VjZhMiAyIDAgMCAwLTItMkg1YTIgMiAwIDAgMC0yIDJ2MTRhMiAyIDAgMCAwIDIgMmg0LjMiIC8+CiAgPHBhdGggZD0iTTMgMTBoNCIgLz4KICA8cGF0aCBkPSJNOCAydjQiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/calendar-sync
 * @see https://lucide.dev/guide/packages/lucide-react - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {JSX.Element} JSX Element
 *
 */
const CalendarSync = createLucideIcon('CalendarSync', [
  ['path', { d: 'M11 10v4h4', key: '172dkj' }],
  ['path', { d: 'm11 14 1.535-1.605a5 5 0 0 1 8 1.5', key: 'vu0qm5' }],
  ['path', { d: 'M16 2v4', key: '4m81vk' }],
  ['path', { d: 'm21 18-1.535 1.605a5 5 0 0 1-8-1.5', key: '1qgeyt' }],
  ['path', { d: 'M21 22v-4h-4', key: 'hrummi' }],
  ['path', { d: 'M21 8.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h4.3', key: 'mctw84' }],
  ['path', { d: 'M3 10h4', key: '1el30a' }],
  ['path', { d: 'M8 2v4', key: '1cmpym' }],
]);

export default CalendarSync;
